import React, { useState, useCallback, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { APIClient } from "../../helpers/api_helper";
import { Link } from "react-router-dom";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import { toast } from "react-toastify";
import { Card, CardHeader, Row, Spinner } from "reactstrap";
import { format } from "date-fns";
import { ptBR, tr } from "date-fns/locale";
import { ethers } from "ethers";
import { useActiveWallet, useActiveWalletChain } from "thirdweb/react";
import BotaoConectar from "../Authentication/BotaoConectar";
import { claimKeeper } from "../../assets/abi/TokenClaimKeeper";
const apiClient = new APIClient();

const GraficoProjeto = ({
  dataColors,
  projeto,
  trocaRede,
  fetchEnvios,
  envios,
  series,
  getLast12Months,
  loading,
  setLoading,
  carteiraEnvio,
}) => {
  const carteira = useActiveWallet();
  const chain = useActiveWalletChain();
  var linechartBasicColors = getChartColorsArray(dataColors);

  const [refresh, setRefresh] = useState(false);
  const [loadingBlockchain, setLoadingBlockchain] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [valorClaim, setValorClaim] = useState(ethers.BigNumber.from(0));

  const formatAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const updateHash = async (hash, projeto, avisar = true) => {
    console.log(hash, projeto);
    const response = await apiClient.put(`/claim/update-hash/${projeto.id}`, {
      hash,
      carteira: carteira?.getAccount()?.address,
    });
    if (response.sucesso) {
      if (avisar) toast.success(response.mensagem);
      setRefresh(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  };

  const getAmountClaim = useCallback(
    async (projeto, carteira, interno = false) => {
      setLoadingBlockchain(true);
      if (chain?.id !== projeto.token.rede.network_id) {
        if (!(await trocaRede(projeto))) return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tokenAddress = projeto.token.contrato;

      const contrato = projeto.contrato?.contrato;
      if (!contrato) {
        if (!interno) setLoadingBlockchain(false);
        return 0;
      }

      let amount = 0;
      try {
        const contract = new ethers.Contract(contrato, claimKeeper, signer);
        amount = await contract.getClaimableAmount(tokenAddress, carteira);
      } catch (error) {
        console.error(error);
        toast.error("Erro ao buscar valor claim");
      }
      setValorClaim(
        amount.div(ethers.BigNumber.from(10).pow(projeto.token.decimais))
      );
      if (!interno) setLoadingBlockchain(false);
      return amount;
    },
    []
  );

  const claim = async (projeto, carteira_id) => {
    setLoadingBlockchain(true);
    if (chain?.id !== projeto.token.rede.network_id) {
      if (!(await trocaRede(projeto))) return;
    }

    const carteiraOn = carteira?.getAccount()?.address;
    if (!carteiraOn) {
      toast.error("Carteira não conectada");
      setLoadingBlockchain(false);
      return;
    }

    const amountClaim = await getAmountClaim(projeto, carteiraOn, true);

    if (amountClaim.lte(0)) {
      toast.error("Não há tokens a serem sacados para esta carteira");
      await updateHash("0x0", projeto);
      setLoadingBlockchain(false);
      setRefresh(true);
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenAddress = projeto.token.contrato;

    const contrato = projeto.contrato?.contrato;
    if (!contrato) {
      toast.error("Erro ao buscar contrato");
      setLoadingBlockchain(false);
      return;
    }

    let receipt;
    try {
      const contract = new ethers.Contract(contrato, claimKeeper, signer);
      let estimateGas;

      try {
        estimateGas = await contract.estimateGas.claimTokens(tokenAddress);
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoadingBlockchain(false);
        return;
      }

      const transactionResponse = await contract.claimTokens(tokenAddress, {
        gasLimit: estimateGas,
      });
      // get the transaction hash before waiting
      let txHash = await transactionResponse.hash;
      await updateHash(txHash, projeto, false);
      receipt = await transactionResponse.wait();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar transação");
      setLoadingBlockchain(false);
      return;
    }
    await updateHash(receipt.transactionHash, projeto);
    setLoadingBlockchain(false);
    setLoading(true);
    setRefresh(true);
  };

  const converterData = (data) => {
    const dataObjeto = new Date(data.replace(" ", "T"));
    return format(dataObjeto, "dd MMM, yyyy HH:mm", { locale: ptBR });
  };

  const goToNextPage = () => {
    if (pagina < Math.ceil(envios.length / 12)) {
      setPagina(pagina + 1);
    }
  };

  const goToPrevPage = () => {
    if (pagina > 1) {
      setPagina(pagina - 1);
    }
  };

  var options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 4,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: linechartBasicColors,
    title: {
      text: "Últimos 12 meses",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },

    xaxis: {
      categories: getLast12Months(),
    },
  };

  useEffect(() => {
    const fetch = async (projeto) => {
      setLoading(true);
      await fetchEnvios(projeto);
      setLoading(false);
    };

    const handleAmountGet = async () => {
      if (projeto?.token?.rede.network_id !== chain?.id)
        await trocaRede(projeto);
      await getAmountClaim(projeto, carteira.getAccount().address);
    };

    if (projeto && loading) fetch(projeto);

    if (projeto && projeto?.token && carteira && chain && chain?.id) {
      handleAmountGet();
    }
  }, [projeto, refresh, chain]);

  return (
    <React.Fragment>
      {loading ? (
        <Row className="justify-content-center align-items-center h-100">
          <Spinner />
        </Row>
      ) : (
        <ReactApexChart
          dir="ltr"
          options={options}
          series={series}
          type="line"
          height="350"
          className="apex-charts"
        />
      )}
      <Row className="mt-5">
        <Card id="envioList">
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">Listagem de envios</h5>
                  {carteira?.getAccount()?.address !== carteiraEnvio && (
                    <>
                      <p className="text-warning mt-1">
                        <small>
                          Sua carteira conectada é diferente da carteira de
                          envio:{" "}
                        </small>
                        <br />
                        <small className="text-danger">
                          <b>Carteira conectada</b>:{" "}
                          {carteira?.getAccount()?.address}
                        </small>
                        <br />
                        <small className="text-danger">
                          <b>Carteira de envio</b>: {carteiraEnvio}
                        </small>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-auto">
                {chain && chain.id ? (
                  valorClaim.gt(0) ? (
                    <div>
                      {!loadingBlockchain && (
                        <span className="text-warning me-2">
                          A sacar: {valorClaim.toString()} {projeto.token.sigla}
                        </span>
                      )}
                      <button
                        type="button"
                        className="btn btn-success btn-sm add-btn me-2"
                        id="create-btn"
                        onClick={() =>
                          claim(projeto, carteira?.getAccount()?.address)
                        }
                        disabled={loadingBlockchain}
                      >
                        {loadingBlockchain ? (
                          <>
                            <Spinner size="sm" /> Sacando Tokens...
                          </>
                        ) : (
                          "Realizar Claim"
                        )}
                      </button>{" "}
                    </div>
                  ) : (
                    <div>
                      {carteira?.getAccount()?.address ===
                      (
                        <span className="text-muted me-2">
                          Não há tokens a serem sacados no momento
                        </span>
                      )}
                      <button
                        type="button"
                        className="btn btn-warning btn-sm add-btn me-2"
                        id="create-btn"
                        onClick={() =>
                          getAmountClaim(
                            projeto,
                            carteira?.getAccount()?.address
                          )
                        }
                        disabled={loadingBlockchain}
                      >
                        Atualizar
                      </button>
                    </div>
                  )
                ) : chain &&
                  chain.id &&
                  chain.id !== projeto.token.rede.network_id ? (
                  <div>
                    <button
                      type="button"
                      className="btn btn-warning btn-sm add-btn me-2"
                      id="create-btn"
                      onClick={() => trocaRede(projeto)}
                    >
                      Trocar para rede {projeto.token.rede.nome}
                    </button>
                  </div>
                ) : (
                  <BotaoConectar />
                )}
              </div>
            </Row>
          </CardHeader>
          <div className="card-body pt-0">
            <div>
              {!loading ? (
                <table className="table table-hover table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">Carteira</th>
                      <th scope="col">Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(envios || []).map((envio, index) => (
                      <tr key={index}>
                        <td>
                          {envio.projeto.token.nome} (
                          {envio.projeto.token.sigla})
                          <br />
                          <small className="text-muted">
                            {converterData(envio.created_at)}
                          </small>
                        </td>
                        <td
                          className="text-success"
                          title={envio.carteira?.carteira}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {formatAddress(envio.carteira?.carteira)}
                        </td>
                        <td>
                          {parseFloat(
                            envio.amount / 10 ** envio.projeto.token.decimais
                          ).toFixed(envio.projeto.token.decimais)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Row className="justify-content-center mt-3 mb-3">
                  <Spinner color="warning" />
                </Row>
              )}
            </div>
            {envios.length > 12 && (
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                  <Link
                    to="#"
                    className="page-link"
                    onClick={goToPrevPage}
                    disabled={pagina === 1}
                  >
                    ←
                  </Link>
                </div>

                <div>
                  <select
                    className="form-select form-select-sm"
                    value={pagina}
                    onChange={(e) => handlePageChange(Number(e.target.value))}
                  >
                    {Array.from(
                      { length: Math.ceil(envios.length / 12) },
                      (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      )
                    )}
                  </select>
                </div>

                <div>
                  <Link
                    to="#"
                    className="page-link"
                    onClick={goToNextPage}
                    disabled={pagina === Math.ceil(envios.length / 12)}
                  >
                    →
                  </Link>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Row>
    </React.Fragment>
  );
};
export { GraficoProjeto };
